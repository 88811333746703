import React from 'react'

const NotFoundPage = () => (
  <>
    <h1>404: Not Found</h1>
    <p>Wybrana strona nie istnieje</p>
  </>
)

export default NotFoundPage
